//********************
//Load Function
//********************
$(window).on("load",function(){
  //Pre Loader
  $('#preloader').delay(350).fadeOut(function(){
    $('body').delay(350).css({'overflow':'visible'});
   });
});

function showList(e) {
  var $gridCont = $('.grid-container');
  e.preventDefault();
  $gridCont.hasClass('list-view') ? $gridCont.removeClass('list-view') : $gridCont.addClass('list-view');
}

function gridList(e) {
  var $gridCont = $('.grid-container')
  e.preventDefault();
  $gridCont.removeClass('list-view');
}

$(document).on('click', '.btn-grid', gridList);
$(document).on('click', '.btn-list', showList);
